.SummaryHeader {
  height: 48px;
  border-bottom: solid 1px #ddd;
  background-color: #efeff4;

  &__menu {
    float: left;
    width: 44px;
    height: 48px;
    border-right: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    float: left;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 12px;
    width: calc(100% - 57px);

    div {
      width: 100%;
      margin-top: -4px;
    }
  }
}

.NewSummaryHeader {
  height: 56px;
  border-bottom: solid 1px #F7F7F7;

  background: #FFFFFF;


  &__menu {
    float: left;
    width: 44px;
    height: 56px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    float: left;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 12px;
    width: calc(100% - 57px);
    padding-top: 8px;

    
  }
}
